.bottom-menu {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 10px;
    overflow: hidden;

    .dropdown-user-link {
        width: 135px;
        overflow: hidden;
    }

    .dark .nav-link.dropdown-user-link {
        color: white;
    }

    .light .nav-link.dropdown-user-link {
        color: rgb(34, 34, 34);
    }

    .nav-link.dropdown-user-link {
        display: flex;
        text-align: right;
        gap: 5px;
    }

    .user-nav {
        flex-direction: column;
    }
}